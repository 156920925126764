<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/comment'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <div class="card">
              <div class="p-4 row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="seoKeyword">Language <strong>*</strong></label>
                    <select
                      required
                      v-model="formData.language_id"
                      id=""
                      class="form-control"
                    >
                      <option
                        v-for="(entry, key) in langs"
                        :key="key"
                        v-bind:value="entry.id"
                        >{{ entry.name }}</option
                      >
                    </select>
                  </div>
                </div>
                <!-- TextBox -->

                <div class="col-lg-6 border-col" v-for="item in 20" :key="item">
                  <div class="row">
                    <div class="col-6">
                      <label :for="'commentTitle' + item"
                        >Name {{ item }}
                      </label>
                      <input
                        v-model="formData['commentTitle' + item]"
                        type="text"
                        class="form-control"
                      />
                    </div>

                    <div class="mb-3 mb-0 col-6">
                      <label :for="'commentOperation' + item"
                        >Operation {{ item }}
                      </label>
                      <input
                        v-model="formData['commentOperation' + item]"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <label>Profile Photo </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('commentImage' + item, $event)"
                      />
                    </div>
                    <div class="mb-3 mb-0 col-12">
                      <label :for="'commentDescription' + item">
                        Comment {{ item }} (max 300 Character)
                      </label>
                      <textarea
                        class="form-control"
                        v-model="formData['commentDescription' + item]"
                        rows="4"
                        maxlength="300"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!-- TextBox end -->
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "Comment Create",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Comment Create",
      items: [
        {
          text: "Comment",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {},
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    async FindLp() {
      await API.Get(API.Comment.LangList).then((response) => {
        if (response.data.status == "success") {
          this.langs = response.data.order;
        } else {
          this.langs = [];
        }
      });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Comment.Create,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/comment");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
